import { ScenarioViewModelInterface } from "@/models/global/ScenarioViewModel.interface";
import { CorporationViewModelInterface } from "@/models/global/CorporationViewModel.interface";

export interface OrganizationViewInterface {
  id: string;
  businessLineId: string;
  countryCode: string;
  title: string;
  fullName: string;
  scenario: ScenarioViewModelInterface;
  corporation: CorporationViewModelInterface;
  inn: string;
  genContractId: string;
  isVatPayer: boolean;
  taxSystem: string;
  hasPendingBlock: boolean;
  blockReasonPhrase: string;
  partnerExecutorRoleType?: ExecutorRoleTypeEnum;
  isInBlackList: boolean;
}

export enum ExecutorRoleTypeEnum {
  Any,
  Forwarding,
  Delivery
}
