


























































































import { uniq } from "lodash/fp";
import InputMask from "@/components/UI/InputMask.vue";
import { OrderItemInterface } from "@/models/order/OrderItem.interface";
import plural from "@/utils/plural";
import { computed, defineComponent, onUpdated, ref } from "vue";
import { useInsuranceCalc } from "@/components/order/compositions/useInsuranceCalc";
import useOrderCreation from "@/components/order/compositions/useOrderCreation";
import useVuelidate from "@vuelidate/core";
import OrderItemModel from "@/models/order/OrderItem.model";
import { required } from "@vuelidate/validators";

export default defineComponent({
  name: "Order",
  components: {
    OrderItemFtl: () => import("@/components/order/OrderItemFtl.vue"),
    OrderItemLtl: () => import("@/components/order/OrderItemLtl.vue"),
    InputMask
  },
  filters: {
    percent(value: number) {
      return value.toFixed(2);
    }
  },
  setup() {
    const form = ref<OrderItemInterface>(new OrderItemModel());
    const v$ = useVuelidate(
      {
        type: { required }
      },
      form
    );

    const {
      insuranceInfo,
      insurancePremium,
      withInsurance,
      orderGuid,
      insCalc,
      insReqLoading
    } = useInsuranceCalc(form.value);

    const {
      create: createOrder,
      createOrderWithInsurance,
      loadingProgress,
      reqMessage,
      reqLoading,
      reqStatus
    } = useOrderCreation(v$, {
      order: form,
      insurancePremium,
      withInsurance,
      orderGuid,
      insuranceInfo
    });

    const isLoading = computed(() => reqLoading.value || insReqLoading.value);

    const onTypeInput = (value: string) => {
      if (
        (form.value.type == "Ftl" || form.value.type == "Fcl") &&
        (value == "Ftl" || value == "Fcl")
      ) {
        form.value.type = value;
        return;
      }

      form.value = new OrderItemModel();
      form.value.type = value;
    };

    return {
      form,
      insurancePremium,
      isLoading,
      loadingProgress,
      reqMessage,
      reqStatus,
      v$,
      createOrder,
      createOrderWithInsurance,
      insCalc,
      onTypeInput
    };
  },
  computed: {
    summary() {
      const loadingAddresses: string[] = [];
      const unloadingAddresses: string[] = [];

      this.form.places.forEach(place =>
        place.loadingAddresses.forEach(loadingAddressObj => {
          if (loadingAddressObj.address.value != "")
            loadingAddresses.push(loadingAddressObj.address.value);
          loadingAddressObj.unloadingAddresses.forEach(
            unloadingAddressesObj => {
              if (unloadingAddressesObj.address.value != "")
                unloadingAddresses.push(unloadingAddressesObj.address.value);
            }
          );
        })
      );

      const loadingAddressesCountString = plural(
        uniq(loadingAddresses).length,
        "адрес погрузки",
        "адреса погрузки",
        "адресов погрузки",
        true
      );
      const unloadingAddressesCountString = plural(
        uniq(unloadingAddresses).length,
        "адрес выгрузки",
        "адреса выгрузки",
        "адресов выгрузки",
        true
      );

      return `${loadingAddressesCountString}, ${unloadingAddressesCountString}`;
    }
  }
});
