import {
  AddressObjectInterface,
  LoadingAddressObjectInterface
} from "@/models/order/AddressObject.interface";
import AddressObjectModel from "@/models/order/AddressObject.model";

export default class LoadingAddressObjectModel extends AddressObjectModel
  implements LoadingAddressObjectInterface {
  unloadingAddresses: AddressObjectInterface[];

  constructor() {
    super();
    this.unloadingAddresses = [
      new AddressObjectModel(this.datePeriod, this.address)
    ];
  }

  addUnloadingAddress() {
    this.unloadingAddresses.push(
      new AddressObjectModel(this.datePeriod, this.address)
    );
  }

  removeUnloadingAddress(unloadingAddressIdx: number) {
    this.unloadingAddresses.splice(unloadingAddressIdx, 1);
  }
}
