import { ContactItemInterface } from "@/models/order/ContactItem.interface";
import returnSplitName from "@/mixins/returnSplitName";
import { ContactInterface } from "@/models/order/Contact.interface";

export default class ContactService {
  static async returnContactWithSplitName(
    contactItem: ContactItemInterface
  ): Promise<ContactInterface> {
    const data = await returnSplitName(contactItem.fullName);

    return {
      ...contactItem,
      ...data,
      additional: "",
      countryCode: contactItem.countryCode
    };
  }
}
