import { generateId } from "@/api/base";
import { RequestStatusEnum } from "@/models/global/RequestStatus.enum";
import { computed, ref } from "vue";
import { UseOrderCreationFuncInterface } from "@/models/order/compositions/UseOrderCreationFunc.interface";
import OrderService from "@/models/order/Order.service";

const useOrderCreation: UseOrderCreationFuncInterface = (v$, reqData) => {
  const reqStatus = ref<RequestStatusEnum>(RequestStatusEnum.Pending);
  const reqMessage = ref<string>("");
  const reqLoading = computed(
    () => reqStatus.value === RequestStatusEnum.Loading
  );
  const loadingProgress = ref<number>(0);
  const _orderService = new OrderService();

  function monitorOperationProgress(operationId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const checkProgress = () => {
        _orderService
          .getOrderUploadOperationProgress(operationId)
          .then(response => {
            if (
              !response.isSuccess ||
              !response.entity ||
              response.entity.progress == null
            ) {
              reject(new Error("Ошибка сервера или прогресс не определён"));
              return;
            }

            const { progress } = response.entity;
            loadingProgress.value = progress;

            if (progress === -1) {
              reject(new Error("Ошибка загрузки"));
              return;
            }

            if (progress === 100) {
              reqMessage.value = "Успех!";
              reqStatus.value = RequestStatusEnum.Success;
              reqData.orderGuid.value = generateId();
              resolve();
              return;
            }

            setTimeout(checkProgress, 500);
          })
          .catch(error => {
            reject(new Error("Ошибка выполнения"));
          });
      };

      checkProgress();
    });
  }

  const create = async () => {
    if (v$.value.$invalid) {
      v$.value.$touch();
      return;
    }

    reqStatus.value = RequestStatusEnum.Loading;
    reqMessage.value = "";

    try {
      const res = await _orderService.createOrder(
        reqData.order.value,
        {
          withInsurance: reqData.withInsurance.value,
          orderGuid: reqData.orderGuid.value,
          insuranceInfo: reqData.insuranceInfo.value
        },
        reqData.order.value.type === "Ftl"
      );

      if (res.isSuccess) {
        loadingProgress.value = 0;
        await monitorOperationProgress(res.entity);
      } else {
        reqMessage.value = `Ошибка данных: ${res.message}`;
        reqStatus.value = RequestStatusEnum.Error;
        return;
      }
    } catch (e) {
      reqStatus.value = RequestStatusEnum.Error;
      reqMessage.value = "Ошибка сервиса!";
    } finally {
      loadingProgress.value = 0;
      reqData.withInsurance.value = false;
    }
  };

  const createOrderWithInsurance = async () => {
    if (Number(reqData.insurancePremium) < 1) {
      reqMessage.value = "Не расчитана страховая премия";
      reqStatus.value = RequestStatusEnum.Error;
      return;
    }
    reqData.withInsurance.value = true;
    await create();
  };

  return {
    reqStatus,
    reqMessage,
    reqLoading,
    loadingProgress,
    create,
    createOrderWithInsurance
  };
};

export default useOrderCreation;
