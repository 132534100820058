import { OrderOptionsItemInterface } from "@/models/order/OrderOptions.interface";

export class PlaceOptionsModel implements OrderOptionsItemInterface {
  cargoCategories: string[];
  cargoSubCategory: string;
  loadingTypes: string[];
  bodyTypes: string[];
  bodyTypeComment: string;
  temperatureRegimes: string[];

  constructor() {
    this.cargoCategories = [];
    this.cargoSubCategory = "";
    this.loadingTypes = [];
    this.bodyTypes = [];
    this.bodyTypeComment = "";
    this.temperatureRegimes = [];
  }
}
