import { OrderItemInterface } from "@/models/order/OrderItem.interface";
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { cloneDeep, pick } from "lodash";
import convertStringToNumber from "@/utils/convertStringToNumber";
import { generateId } from "@/api/base";
import { compose, map, sum } from "lodash/fp";
import PersonService from "@/models/person/Person.service";
import { DimensionsInterface } from "@/models/order/Dimensions.interface";
import ContactService from "@/models/person/Contact.service";
import { PlaceRequestInterface } from "@/models/order/PlaceRequest.interface";
import { OrderRequestInterface } from "@/models/order/OrderRequest.interface";
import {
  createOrder,
  createOrders,
  getDefaultPlaceOwner,
  postDefaultPlaceOwner,
  getOrderUploadOperationProgress
} from "@/api/order";
import { InsuranceResponseInterface } from "@/models/order/InsuranceResponse";
import { ErrorResponseInterface } from "@/models/api/ErrorResponse.interface";
import { DataResponseInterface } from "@/models/api/DataResponse.interface";
import { OrderServiceInterface } from "@/models/order/Order.service.interface";
import { DimensionsFormInterface } from "@/models/order/DimensionsForm.interface";
import DimensionsFormModel from "@/models/order/DimensionsForm.model";
import { PlaceItemInterface } from "@/models/order/PlaceItem.interface";
import { NumberHelper } from "@/utils/Number.helper";
import { PeriodItemInterface } from "@/models/order/PeriodItem.interface";
import { integerDivision } from "@/utils/integerDivision";
import { toTimeString } from "@/utils/toTimeString";
import { addDays, format } from "date-fns";
import { OrderTypeEnum } from "@/models/order/OrderType.enum";
import { PersonInterface } from "@/models/person/Person.interface";
import { UploadOperationProgressInterface } from "./UploadOperationProgress.interface";
import {
  ExecutorRoleTypeEnum,
  OrganizationViewInterface
} from "@/models/global/OrganizationView.interface";

export default class OrderService implements OrderServiceInterface {
  async createOrders(
    orders: OrderItemInterface[]
  ): Promise<ErrorResponseInterface | DataResponseInterface<string>> {
    const insuranceData = {
      withInsurance: false,
      orderGuid: "",
      insuranceInfo: null
    };
    const isFtl = true;

    const req = await Promise.all(
      orders.map(order => this._mapOrderRequest(order, insuranceData, isFtl))
    );

    return createOrders(req);
  }

  async createOrder(
    orderItem: OrderItemInterface,
    insuranceData: {
      withInsurance: boolean;
      orderGuid: string;
      insuranceInfo: InsuranceResponseInterface | null;
    } = { withInsurance: false, orderGuid: "", insuranceInfo: null },
    isFtl = true
  ): Promise<ErrorResponseInterface | DataResponseInterface<string>> {
    const req = await this._mapOrderRequest(orderItem, insuranceData, isFtl);

    return await createOrder(req);
  }

  static async getDefaultPlaceOwner(): Promise<
    ErrorResponseInterface | DataResponseInterface<PersonInterface>
  > {
    return await getDefaultPlaceOwner();
  }

  static async postDefaultPlaceOwner(
    sender: OrderRequestInterface[]
  ): Promise<ErrorResponseInterface | DataResponseInterface> {
    return await postDefaultPlaceOwner(sender);
  }

  async getOrderUploadOperationProgress(
    operationId: string
  ): Promise<
    | ErrorResponseInterface
    | DataResponseInterface<UploadOperationProgressInterface>
  > {
    return await getOrderUploadOperationProgress(operationId);
  }

  async _mapOrderRequest(
    orderItem: OrderItemInterface,
    insuranceData: {
      withInsurance: boolean;
      orderGuid: string;
      insuranceInfo: InsuranceResponseInterface | null;
    } = { withInsurance: false, orderGuid: "", insuranceInfo: null },
    isFtl = true
  ): Promise<OrderRequestInterface> {
    const req = {
      ...pick(orderItem, [
        "comment",
        "tax",
        "type"
      ] as (keyof OrderItemInterface)[]),
      cost: {
        paymentMethod: "CARD",
        assessedValue: convertStringToNumber(orderItem.assessedValue),
        fullyPrepaid: false,
        manualDeliveryForCustomer: 0
      },
      customer: {
        id: orderItem.customer.id,
        type: orderItem.customer.type,
        name: orderItem.customer.name,
        inn: orderItem.customer.inn,
        fullName: orderItem.customer.fullName,
        firstName: orderItem.customer.firstName,
        middleName: orderItem.customer.middleName,
        lastName: orderItem.customer.lastName,
        address: orderItem.customer.address,
        countryCode: orderItem.customer.countryCode
      },
      deliveryServiceExternalId: "",
      userId: "",
      withInsurance: insuranceData.withInsurance,
      externalId: orderItem.externalId,
      executorRoleType: orderItem.executorRoleType.toString(),
      isForwarded: orderItem.orderType != OrderTypeEnum.ToYourself,
      forwardToOrganizationIds: [],
      forwardedPrice: null,
      auctionTime: "",
      auctionBidStep: 0,
      cancellationFreeOfChargeFeeForDelay: "",
      cancellationFreeOfChargeHours: "",
      daysAfterDocumentsReceiveForPayment: "",
      idleHoursForOrder: "",
      pricePerHourForExcessTime: "",
      otherConditionsText: "",
      executorLineClientId: orderItem.executorOrganization?.id ?? "",
      options: {
        cargoCategories: orderItem.options.cargoCategories,
        cargoSubCategory: orderItem.options.cargoSubCategory,
        bodyTypes: orderItem.options.bodyTypes,
        bodyTypeComment: orderItem.options.bodyTypeComment,
        loadingTypes: orderItem.options.loadingTypes,
        temperatureRegimes: orderItem.options.temperatureRegimes,
        wouldBeLoaded: orderItem.options.wouldBeLoaded,
        allInSingleTransport: orderItem.options.allInSingleTransport
      },
      insuranceInfo: insuranceData.insuranceInfo,
      id: !insuranceData.orderGuid ? generateId() : insuranceData.orderGuid,
      price: 0,
      places: (
        await Promise.all(
          orderItem.places.map(async (place, placeIndex) => {
            let fullVolume: number;
            let fullWeight: number;
            let fullLength: number;
            let fullWidth: number;
            let fullHeight: number;
            let partsCount: number;

            if (orderItem.options.fillFullVolume) {
              fullVolume = convertStringToNumber(orderItem.dimensions.volume);
              fullWeight = convertStringToNumber(orderItem.dimensions.weight);
              fullWidth = convertStringToNumber(orderItem.dimensions.width);
              fullLength = convertStringToNumber(orderItem.dimensions.length);
              fullHeight = convertStringToNumber(orderItem.dimensions.height);
              partsCount = orderItem.places.reduce((prev, place) => {
                return (
                  prev +
                  place.loadingAddresses.reduce((prev, loadingAddress) => {
                    return prev + loadingAddress.unloadingAddresses.length;
                  }, 0)
                );
              }, 0);
            } else {
              fullVolume = convertStringToNumber(place.dimensions.volume);
              fullWeight = convertStringToNumber(place.dimensions.weight);
              fullWidth = convertStringToNumber(place.dimensions.width);
              fullLength = convertStringToNumber(place.dimensions.length);
              fullHeight = convertStringToNumber(place.dimensions.height);
              partsCount = place.loadingAddresses.reduce(
                (prev, loadingAddress) => {
                  return prev + loadingAddress.unloadingAddresses.length;
                },
                0
              );
            }

            const onePartVolume = NumberHelper.floor(
              fullVolume / partsCount,
              -2
            );
            const onePartWeight = NumberHelper.floor(
              fullWeight / partsCount,
              -2
            );
            const onePartHeight = NumberHelper.floor(
              fullHeight / partsCount,
              -2
            );
            const onePartWidth = NumberHelper.floor(fullWidth / partsCount, -2);
            const onePartLength = NumberHelper.floor(
              fullLength / partsCount,
              -2
            );

            const calculateLastDimension = (
              fullDimension: number,
              onePartDimension: number,
              partsCount: number
            ) => {
              const calculatedValue =
                (fullDimension * 100 -
                  onePartDimension * 100 * (partsCount - 1)) /
                100;
              return Math.round(calculatedValue * 100) / 100;
            };

            const lastPartVolume = calculateLastDimension(
              fullVolume,
              onePartVolume,
              partsCount
            );
            const lastPartWeight = calculateLastDimension(
              fullWeight,
              onePartWeight,
              partsCount
            );
            const lastPartWidth = calculateLastDimension(
              fullWidth,
              onePartWidth,
              partsCount
            );
            const lastPartLength = calculateLastDimension(
              fullLength,
              onePartLength,
              partsCount
            );
            const lastPartHeight = calculateLastDimension(
              fullHeight,
              onePartHeight,
              partsCount
            );

            const isLastPlace = placeIndex === orderItem.places.length - 1;

            function returnPlaceDimensions(
              isLtl: boolean,
              isLastPlaceRequest: boolean
            ): DimensionsInterface {
              if (isLtl) {
                return {
                  volume: convertStringToNumber(place.dimensions.volume),
                  weight: convertStringToNumber(place.dimensions.weight),
                  length: convertStringToNumber(place.dimensions.length),
                  width: convertStringToNumber(place.dimensions.width),
                  height: convertStringToNumber(place.dimensions.height)
                };
              }

              if (isLastPlaceRequest) {
                return {
                  volume: lastPartVolume,
                  weight: lastPartWeight,
                  length: lastPartLength,
                  width: lastPartWidth,
                  height: lastPartHeight
                };
              }

              return {
                volume: onePartVolume,
                weight: onePartWeight,
                length: onePartLength,
                width: onePartWidth,
                height: onePartHeight
              };
            }

            return (
              await Promise.all(
                place.loadingAddresses.map(
                  async (loadingAddressObj, loadingAddressIndex) => {
                    return await Promise.all(
                      loadingAddressObj.unloadingAddresses.map(
                        async (unloadingAddressObj, unloadingAddressIndex) => {
                          const senderWithSplitName = await PersonService.returnPersonWithSplitName(
                            loadingAddressObj.person
                          );

                          const recipientWithSplitName = await PersonService.returnPersonWithSplitName(
                            unloadingAddressObj.person
                          );

                          const isLastUnloadingAddress =
                            loadingAddressIndex ===
                              place.loadingAddresses.length - 1 &&
                            unloadingAddressIndex ===
                              loadingAddressObj.unloadingAddresses.length - 1;

                          const placeRequest: PlaceRequestInterface = {
                            count: 1,
                            externalId: generateId(),
                            name: `[${orderItem.type}] ${place.name}`,
                            barcode: place.barcode,
                            tax: isFtl ? orderItem.tax : place.tax,
                            options: isFtl ? orderItem.options : place.options,
                            comment: isFtl ? orderItem.comment : place.comment,
                            items: [],
                            isFragile: false,
                            loadingAddress: loadingAddressObj.address.returnAddress(),
                            unloadingAddress: unloadingAddressObj.address.returnAddress(),
                            dimensions: returnPlaceDimensions(
                              orderItem.type.toLowerCase() === "ltl",
                              orderItem.options.fillFullVolume
                                ? isLastPlace && isLastUnloadingAddress
                                : isLastUnloadingAddress
                            ),
                            assessedValue: convertStringToNumber(
                              place.assessedValue
                            ),
                            deliveryPrice: convertStringToNumber(
                              place.deliveryPrice
                            ),
                            shipment: {
                              dateFrom: loadingAddressObj.datePeriod.dateFrom,
                              dateTo: loadingAddressObj.datePeriod.isInterval
                                ? loadingAddressObj.datePeriod.dateTo
                                : loadingAddressObj.datePeriod.dateFrom,
                              hourFrom: loadingAddressObj.datePeriod.hourFrom,
                              hourTo: loadingAddressObj.datePeriod.isInterval
                                ? loadingAddressObj.datePeriod.hourTo
                                : loadingAddressObj.datePeriod.hourFrom,
                              range: +loadingAddressObj.datePeriod.range
                            },
                            delivery: {
                              dateFrom: unloadingAddressObj.datePeriod.dateFrom,
                              dateTo: unloadingAddressObj.datePeriod.isInterval
                                ? unloadingAddressObj.datePeriod.dateTo
                                : unloadingAddressObj.datePeriod.dateFrom,
                              hourFrom: unloadingAddressObj.datePeriod.hourFrom,
                              hourTo: unloadingAddressObj.datePeriod.isInterval
                                ? unloadingAddressObj.datePeriod.hourTo
                                : unloadingAddressObj.datePeriod.hourFrom,
                              range: +unloadingAddressObj.datePeriod.range
                            },
                            sender: {
                              ...senderWithSplitName,
                              id: 0
                            },
                            recipient: { ...recipientWithSplitName, email: "" },
                            senderContacts: await Promise.all(
                              loadingAddressObj.contacts.map(
                                ContactService.returnContactWithSplitName
                              )
                            ),
                            recipientContacts: await Promise.all(
                              unloadingAddressObj.contacts.map(
                                ContactService.returnContactWithSplitName
                              )
                            )
                          };

                          return new Array(
                            convertStringToNumber(unloadingAddressObj.count)
                          )
                            .fill(null)
                            .map(() => cloneDeep(placeRequest));
                        }
                      )
                    );
                  }
                )
              )
            ).flat(2);
          })
        )
      ).flat()
    } as OrderRequestInterface;

    req.otherConditionsText = orderItem.conditions.otherConditions;

    if (req.isForwarded) {
      const conditions = orderItem.conditions;
      const forwardedPrice = orderItem.forwardedPrice
        ? convertStringToNumber(orderItem.forwardedPrice)
        : null;
      const orderType = orderItem.orderType;
      const forwardToOrganizations =
        orderItem.forwardToOrganizations &&
        orderType === OrderTypeEnum.ToExecutor
          ? orderItem.forwardToOrganizations.filter(
              o => o?.isInBlackList === false
            )
          : orderItem.forwardToOrganizations;

      req.auctionBidStep = convertStringToNumber(orderItem.auctionBidStep);
      req.auctionExtendActivationTime = orderItem.auctionExtendActivationTime;
      req.auctionExtendTime = orderItem.auctionExtendTime;
      req.auctionTime = orderItem.auctionTime;
      req.extendTimeLimit = orderItem.extendTimeLimit;
      req.forwardedPrice = forwardedPrice;
      req.forwardToOrganizationIds =
        forwardToOrganizations?.map(o => o.id) ?? [];
      req.daysAfterDocumentsReceiveForPayment = convertStringToNumber(
        conditions.defermentOfPaymentDays
      ).toString();
      req.documentExchangeDaysAfterFreightUnloading = convertStringToNumber(
        conditions.documentExchangeDaysAfterFreightUnloading
      ).toString();
      req.documentsFormat = conditions.documentsFormat;
      req.isAuction =
        orderItem.isAuction ||
        orderType === OrderTypeEnum.ToHub ||
        (orderType === OrderTypeEnum.ToExecutor &&
          (forwardToOrganizations?.length ?? 0) >= 2);
      req.isAuctionAutoComplete =
        orderType == OrderTypeEnum.ToExecutor &&
        (forwardToOrganizations?.length ?? 0) >= 2 &&
        orderItem.isAuctionAutoComplete;
      req.isAuctionAutoExtend = orderItem.isAuctionAutoExtend;
      req.isPriceOnlyDescend =
        orderType != OrderTypeEnum.ToYourself &&
        orderItem.isPriceOnlyDescend &&
        forwardedPrice != 0 &&
        orderItem.forwardedPrice != "";

      req.isAuctionAutoExtend = orderItem.isAuctionAutoExtend;
      req.extendTimeLimit = orderItem.extendTimeLimit;
      req.auctionExtendActivationTime = orderItem.auctionExtendActivationTime;
      req.auctionExtendTime = orderItem.auctionExtendTime;
      req.documentExchangeDaysAfterFreightUnloading = convertStringToNumber(
        orderItem.conditions.documentExchangeDaysAfterFreightUnloading
      ).toString();
      req.daysAfterDocumentsReceiveForPayment = convertStringToNumber(
        orderItem.conditions.defermentOfPaymentDays
      ).toString();
      req.documentsFormat = orderItem.conditions.documentsFormat;
    }

    const shipmentDates: Date[] = [];
    const deliveryDates: Date[] = [];

    req.places.forEach(place => {
      place.externalId = generateId();
      shipmentDates.push(new Date(place.shipment.dateFrom));
      deliveryDates.push(new Date(place.delivery.dateFrom));
    });

    shipmentDates.sort((a, b) => {
      return a > b ? 1 : a === b ? 0 : -1;
    });
    deliveryDates.sort((a, b) => {
      return a > b ? 1 : a === b ? 0 : -1;
    });

    req.contractData = {
      startDate: format(shipmentDates[0], "yyyy-MM-dd"),
      createDate: format(shipmentDates[0], "yyyy-MM-dd"),
      endDate: format(
        addDays(deliveryDates[deliveryDates.length - 1], 70),
        "yyyy-MM-dd"
      ),
      payDate: format(
        addDays(deliveryDates[deliveryDates.length - 1], 40),
        "yyyy-MM-dd"
      )
    };
    return req;
  }

  static setDatePeriodHour(
    datePeriod: PeriodItemInterface,
    hour: string,
    isHourTo = false
  ): void {
    const key: keyof Pick<PeriodItemInterface, "hourFrom" | "hourTo"> = isHourTo
      ? "hourTo"
      : "hourFrom";

    if (hour.replace("_", "").length < 5) {
      datePeriod[key] = hour;
      return;
    }

    // eslint-disable-next-line prefer-const
    let [hours, minutes] = hour.split(":", 2).map(val => +val);

    if (hours > 24 || minutes > 60) {
      datePeriod[key] = hour;
      return;
    }

    minutes = 15 * (integerDivision(minutes, 15) + (minutes % 15 > 7 ? 1 : 0));

    datePeriod[key] = toTimeString(hours, minutes, 0, "HH:mm");
  }

  private divideDimensionToPlaces(
    order: OrderItemInterface,
    dimensionName: keyof DimensionsFormInterface,
    value: string
  ) {
    const numericValue = convertStringToNumber(value);
    const dividedNumVal = numericValue / order.places.length;
    const dividedNumValRounded = Math.round(dividedNumVal * 100) / 100;
    const dividedVal = NumberHelper.numberToString(dividedNumValRounded);

    order.places.forEach(place => {
      place.dimensions[dimensionName] = dividedVal;
    });
  }

  setDimensionToOrder(
    order: OrderItemInterface,
    dimensionName: keyof DimensionsFormInterface,
    value: string
  ): void {
    order.dimensions[dimensionName] = value;
    this.divideDimensionToPlaces(order, dimensionName, value);

    if (["weight", "volume"].includes(dimensionName)) return;

    const orderVolumeValue = DimensionsFormModel.calcVolume(order.dimensions);
    this.setDimensionToOrder(order, "volume", orderVolumeValue);
  }

  setDimensionToOrderPlace(
    order: OrderItemInterface,
    placeIdx: number,
    dimensionName: keyof DimensionsFormInterface,
    value: string
  ): void {
    const place = order.places[placeIdx];
    place.dimensions[dimensionName] = value;

    order.dimensions[dimensionName] = compose(
      NumberHelper.numberToString,
      sum,
      map((place: PlaceItemInterface) =>
        convertStringToNumber(place.dimensions[dimensionName])
      )
    )(order.places);

    if (["weight", "volume"].includes(dimensionName)) return;

    const placeVolumeValue = DimensionsFormModel.calcVolume(place.dimensions);
    this.setDimensionToOrderPlace(order, placeIdx, "volume", placeVolumeValue);
  }

  private divideAssessedValueToPlaces(
    order: OrderItemInterface,
    value: string
  ) {
    const numericValue = convertStringToNumber(value);
    const dividedValue = NumberHelper.numberToString(
      numericValue / order.places.length
    );

    order.places.forEach(place => {
      place.assessedValue = dividedValue;
    });
  }

  private dividePriceToPlaces(order: OrderItemInterface, value: string) {
    const numericValue = convertStringToNumber(value);
    const dividedValue = NumberHelper.numberToString(
      numericValue / order.places.length
    );

    order.places.forEach(place => {
      place.deliveryPrice = dividedValue;
    });
  }

  setAssessedValueToOrder(order: OrderItemInterface, value: string) {
    order.assessedValue = value;
    this.divideAssessedValueToPlaces(order, value);
  }

  setPriceToOrder(order: OrderItemInterface, value: string) {
    order.price = value;
    this.dividePriceToPlaces(order, value);
  }

  setAssessedValueToOrderPlace(
    order: OrderItemInterface,
    placeIdx: number,
    value: string
  ) {
    const place = order.places[placeIdx];
    place.assessedValue = value;

    order.assessedValue = compose(
      NumberHelper.numberToString,
      sum,
      map((place: PlaceItemInterface) =>
        convertStringToNumber(place.assessedValue)
      )
    )(order.places);
  }

  addPlaceToOrder(order: OrderItemInterface) {
    order.addPlace();

    if (order.type !== "Ftl") return;

    this.dividePriceToPlaces(order, order.price);

    if (order.options.fillFullVolume) {
      Object.keys(order.dimensions).forEach(dimensionName => {
        this.divideDimensionToPlaces(
          order,
          dimensionName as keyof DimensionsFormInterface,
          order.dimensions[dimensionName as keyof DimensionsFormInterface]
        );
      });
    }

    if (order.options.fillFullAssessedValue) {
      this.divideAssessedValueToPlaces(order, order.assessedValue);
    }
  }
}
